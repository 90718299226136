import { useState, useCallback } from "react"
import { getBaseUrl } from "../utils/deploymentUtils"
import { protectedResources } from "../authConfig"

// eslint-disable-next-line no-unused-vars
import { InteractionType, PopupRequest } from "@azure/msal-browser"
import { useMsal, useMsalAuthentication } from "@azure/msal-react"

/**
 * Custom hook to call a web API using bearer token obtained from MSAL
 * @param {PopupRequest} msalRequest
 * @returns
 */
const useFetchWithMsal = (msalRequest) => {
  const { instance } = useMsal()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const msalRequestDefault =
    msalRequest === undefined
      ? {
          scopes: protectedResources.apiTodoList.scopes.read,
        }
      : msalRequest

  const { result, error: msalError } = useMsalAuthentication(
    InteractionType.Redirect,
    {
      ...msalRequestDefault,
      account: instance.getActiveAccount(),
      redirectUri: "/", // redirectUri should be to the current page
    }
  )

  /**
   * Execute a fetch request with the given options
   * @param {string} method: GET, POST, PUT, DELETE
   * @param {String} endpoint: The endpoint to call
   * @param {Object} data: The data to send to the endpoint, if any
   * @returns JSON response
   */
  const execute = async (method, endpoint, data = null) => {
    if (msalError) {
      setError(msalError)
      return
    }

    if (result) {
      try {
        let response = null

        const headers = new Headers()
        const bearer = `Bearer ${result.accessToken}`
        headers.append("Authorization", bearer)

        if (data) headers.append("Content-Type", "application/json")

        let options = {
          method: method,
          headers: headers,
          body: data ? JSON.stringify(data) : null,
        }

        setIsLoading(true)

        console.log("calling: " + getBaseUrl() + endpoint)

        response = await (await fetch(getBaseUrl() + endpoint, options)).json()
        setData(response)

        setIsLoading(false)
        return response
      } catch (e) {
        console.log("error", e)

        setError(e)
        setIsLoading(false)
        throw e
      }
    }
  }

  return {
    isLoading,
    error,
    data,
    execute: useCallback(execute, [result, msalError]), // to avoid infinite calls when inside a `useEffect`
  }
}

export default useFetchWithMsal

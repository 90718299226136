import { AuthenticatedTemplate } from "@azure/msal-react"
import { useMsal } from "@azure/msal-react"
import Page from "../components/Page"
import { PermissionsContext } from "../App"
import { pageSections } from "../components/NavigationBar"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { hasPermission } from "../utils/permissions"

export const Home = () => {
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const [permissions] = useContext(PermissionsContext)
  const navigateTo = useNavigate()

  return (
    <Page>
      <AuthenticatedTemplate>
        {activeAccount ? <div>Hello, {activeAccount.name}!</div> : null}
        <div>
          {pageSections
            .filter((section) => section.name !== "Home")
            .map((section, index) => {
              if (
                !section.permissions ||
                section.permissions.length === 0 ||
                hasPermission(permissions, section.permissions)
              ) {
                return (
                  <div key={section.name}>
                    <div
                      style={{
                        fontWeight: "bold",
                        marginBottom: "5px",
                        marginTop: "20px",
                      }}
                    >
                      {section.name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      {section.pages.map((page) => {
                        if (hasPermission(permissions, page.permissions)) {
                          return (
                            <div
                              style={{
                                cursor: "pointer",
                                padding: "5px",
                                border: "1px solid black",
                                borderRadius: "5px",
                              }}
                              key={page.name}
                              onClick={() => navigateTo(page.location)}
                            >
                              {page.name}
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
        </div>
      </AuthenticatedTemplate>
    </Page>
  )
}
